import * as React from 'react';

import Layout from '../components/Base/Layout';
import { SEO } from '../components/Base/Seo';
import SendPasswordResetEmailForm from '../components/Login/SendPasswordReset';
import UnAuthContent from '../components/Login/UnAuthContent';

const PasswordReset: React.FC = () => {
	return (
		<>
			<Layout>
				<UnAuthContent>
					<SendPasswordResetEmailForm />
				</UnAuthContent>
			</Layout>
		</>
	);
};

export const Head = () => (
	<SEO
		title='Doctormedica.co | Forgot Password'
		description='Reset your password here'
	/>
);

export default PasswordReset;
